<template>
    <div class="section" key="children">
        <div class="section-title">
            <div class="author"><img width="50" height="50" src="@/assets/images/rabier.png"></div>
            <h3><span>Schritt 8</span> Gibt es gemeinsame Kinder?</h3>
            <p>Bitte teilen Sie mit, ob Sie gemeinsame minderjährige Kinder haben</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.kinder" name="kinder" value="ja" id="radio-11">
            <label for="radio-11" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-checked-100.svg"></span><span>Ja</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.kinder" name="kinder" value="nein" id="radio-12">
            <label for="radio-12" v-on:click="incrementChecked()"><span class="label-icon"><img src="@/assets/images/icons8-cancel-100.svg"></span><span>Nein</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Children',
  computed: mapState({
    user: (state) => state.user,
    checked: (state) => state.checked
  }),
  methods: {
    ...mapMutations([
      'incrementChecked'
    ]),
  }
}
</script>